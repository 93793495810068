import { Client } from '@stomp/stompjs'
import SockJS from 'sockjs-client'
import { getAuthToken } from '@/utils/auth'
import urls from '@/utils/urls'

let connection = null
function socketFactory(questionnaireCode) {
  const url = urls.ASSIGNMENTS_API
  const path = 'challenge-rooms'

  return new SockJS(`${url}/${path}/${questionnaireCode}`)
}
function getConfig(questionnaireCode) {
  const token = getAuthToken()?.replace('Bearer ', '')

  return {
    webSocketFactory: () => socketFactory(questionnaireCode),
    connectHeaders: { token },
    connectionTimeout: 0,
    reconnectDelay: 5000,
    heartbeatIncoming: 4000,
    heartbeatOutgoing: 4000,
  }
}
function getClient(questionnaireCode) {
  if (!connection) {
    connection = {
      questionnaireCode,
      client: new Client(getConfig(questionnaireCode)),
    }
    connection.client.activate()
  }

  return connection.client
}
function clientIsConnected() {
  return connection?.client?.active || false
}
function subscribe(topic, callback, headers) {
  if (!clientIsConnected()) {
    throw new Error('CLIENT DISCONNECTED')
  }

  return connection.client.subscribe(topic, callback, headers)
}
function unsubscribe(subscription) {
  subscription.unsubscribe()
}
function emit(destination, data = {}) {
  let body = data

  if (!clientIsConnected()) {
    throw new Error('CLIENT DISCONNECTED')
  }

  if (typeof data === 'object') {
    body = JSON.stringify(data)
  }

  connection.client.publish({ destination, body })
}
function disconnect() {
  if (clientIsConnected()) {
    connection.client.deactivate()
  }

  connection = null
}
export default {
  getClient,
  subscribe,
  unsubscribe,
  emit,
  disconnect,
  clientIsConnected,
}
