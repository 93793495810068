<template>
  <div
    data-testid="mission-screen"
    class="mission"
    :style="missionBackground"
  >
    <StatusConnection />
    <transition name="header">
      <div
        :class="[
          'mission__mission-header',
          { '--compass-animation': activeTruthCompassAnim },
        ]"
      >
        <TeacherTopbar
          v-if="isTeacherTopbarVisible"
          selector-is-hidden
        />
        <MissionHeader
          v-if="initReady"
          @quit-mission="quitMissionClicked"
        />
      </div>
    </transition>
    <div
      v-if="!mq_xl__mf"
      class="header__content"
    >
      <h2 class="title">
        {{ title }}
      </h2>
      <h3 class="subtitle">
        {{ subtitle }}
      </h3>
    </div>

    <div class="mission__body">
      <transition name="question-content">
        <MissionContent
          v-if="contentReady"
          ref="missionContent"
          :selected-option="selectedOption"
          :removed-option="optionToDisable"
          :ready="initReady"
          @optionClicked="optionClicked"
        />
      </transition>
      <transition-group
        tag="div"
        class="quick-actions"
        name="quick-actions"
      >
        <BagButton
          v-if="bagButtonReady && !isMobile"
          key="bag"
          :class="{
            '--unclickable':
              question.loading ||
              question.error ||
              progress.loading ||
              feedbackReady,
          }"
          @click="openBag"
        />
        <QuickItemButton
          v-for="item in quickItems"
          :key="item"
          :item="item"
          @click="setUseItem(item)"
        />
      </transition-group>
      <transition name="feedback">
        <MissionFooter
          :feedback-is-visible="feedbackReady"
          :is-mobile="isMobile"
        >
          <template #feedback>
            <MissionFeedback
              v-if="feedbackReady"
              :correct="answer.data.correct"
              :can-be-finished="progress.data.canBeFinished"
              @nextEnigma="nextEnigma"
              @showExplanation="showExplanation"
              @finishMission="showReport"
            />
            <MissionActions
              v-else-if="canBeAnswered"
              @sendAnswer="handleSendAnswer"
            />
          </template>
          <template #bag>
            <BagButton
              v-if="bagButtonReady"
              key="bag"
              :is-mobile="isMobile"
              :class="{
                '--unclickable':
                  question.loading ||
                  question.error ||
                  progress.loading ||
                  feedbackReady,
              }"
              @click="openBag"
            />
          </template>
        </MissionFooter>
      </transition>
      <transition name="truth-compass">
        <div
          :class="[
            'mission__compass-animation',
            {'--max-width': activeTruthCompassAnim}]"
        >
          <CompassAnimation
            ref="truthCompass"
            :active="activeTruthCompassAnim"
            @anim-complete="handleAnimComplete"
          />
        </div>
      </transition>
    </div>

    <QuitMissionModal
      v-if="showQuitModal"
      @cancel="cancelQuitMission"
      @ok="quitMission"
    />
    <RewardModal @close="handleCloseModal" />
    <Bag
      v-if="bagReady"
      ref="bag"
      :waiting-for-item="waitingForItem"
      :use-item-error.sync="useItemError"
      @use-item="setUseItem($event)"
    />
    <WiseScroll
      ref="wiseScroll"
      :assignment-code="assignmentCode"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { playSoundFX } from '@/utils/soundEffects'
import { effectsNames } from '@/service/soundController/loadSounds'
import loadTheme from '@/mixins/loadTheme'
import userIsStudent from '@/mixins/userIsStudent'
import gradeId from '@/mixins/gradeId'
import mediaQueries from '@/mixins/mediaQueries'
import Bag from '@/components/Bag/Bag'
import StatusConnection from '@/components/StatusConnection'
import TeacherTopbar from '../../components/TeacherTopbar'
import CompassAnimation from '../Missions/partials/CompassAnimation'
import MissionHeader from './partials/MissionHeader'
import MissionContent from './partials/MissionContent'
import MissionActions from './partials/MissionActions'
import MissionFeedback from './partials/MissionFeedback'
import QuitMissionModal from './partials/QuitMissionModal'
import RewardModal from './partials/RewardModal/RewardModal'
import BagButton from './partials/BagButton'
import QuickItemButton from './partials/QuickItemButton'
import WiseScroll from './partials/WiseScroll'
import MissionFooter from './partials/MissionFooter'

const possibleQuickItems = [ 'wiseScroll', 'missionBook' ]

export default {
  name: 'Mission',
  components: {
    MissionHeader,
    MissionContent,
    MissionFooter,
    StatusConnection,
    MissionActions,
    MissionFeedback,
    QuitMissionModal,
    RewardModal,
    BagButton,
    QuickItemButton,
    WiseScroll,
    Bag,
    CompassAnimation,
    TeacherTopbar,
  },
  mixins: [
    loadTheme,
    userIsStudent,
    gradeId,
    mediaQueries,
  ],
  data() {
    return {
      activeTruthCompassAnim: false,
      selectedOption: null,
      canDisableOption: true,
      showQuitModal: false,
      initReady: false,
      contentReady: false,
      bagReady: false,
      bagButtonReady: false,
      waitingForItem: false,
      useItemError: false,
      option: -1,
    }
  },
  computed: {
    ...mapGetters([
      'userGameData',
      'theme',
      'performance',
      'question',
      'progress',
      'answer',
      'itemEffects',
      'pageflip',
      'usedItems',
      'assignment',
      'user',
      'userGrades',
    ]),
    title() {
      return this.assignment.data?.title
        ? this.assignment.data.title
        : ''
    },
    subtitle() {
      return this.assignment.data
        && this.assignment.data.subtitle
        ? this.assignment.data.subtitle
        : ''
    },
    isMobile() {
      return !this.mq_xl__mf
    },
    quickItems() {
      if (
        !this.usedItems.success
          || this.question.loading
          || this.progress.loading
          || this.answer.loading
          || this.feedbackReady
      ) {
        return []
      }

      const questionItems = this.usedItems.data.find((it) => it?.category === 'QUESTION')?.items ?? []

      return questionItems.filter(
        (it) => possibleQuickItems.includes(it?.id)
      )?.map((it) => it?.id) ?? []
    },
    lectureId() {
      return parseInt(this.$route.params.lectureId, 10)
    },
    assignmentCode() {
      return this.question.success ? this.question.data.assignment.code : ''
    },
    questionnaireCode() {
      return this.$route.params.questionnaireCode
    },
    missionBackground() {
      if (this.theme.success) {
        if (this.theme.data.mission.background) {
          return `background-image: url(${this.theme.data.mission.background})`
        }
        if (this.theme.data.sky.background) {
          return `background-color: ${this.theme.data.sky.background}`
        }
      }

      return ''
    },
    canBeAnswered() {
      return Boolean(this.selectedOption)
    },
    feedbackReady() {
      return (
        this.question.success && this.answer.success && this.progress.success
      )
    },
    initReadyWatcher() {
      return (
        (this.userGameData.success || this.userGameData.error)
          && !this.question.loading
      )
    },
    optionToDisable() {
      if (this.usedItems.data?.length && this.canDisableOption) {
        const [ questionItems ] = this.usedItems.data?.filter(
          ({ category }) => category === 'QUESTION'
        )

        if (questionItems?.items.length) {
          const item = questionItems.items.find(
            ({ id }) => id === 'truthCompass'
          )

          return item ? parseInt(item.itemOutcome, 10) : -1
        }
      }

      return -1
    },
    gradeId() {
      const teacherGradeId = parseInt(this.$route.query.gradeId, 10)
      if (this.user.data?.userIsStudent) {
        return null
      }

      return teacherGradeId
    },
    isTeacherTopbarVisible() {
      return !this.user?.data?.userIsStudent && this.userGrades?.data?.length
    },
    itemEffectErrorMessage() {
      return this.itemEffects?.error?.message
      || this.$t('game.error.useItem.message')
    },
  },
  watch: {
    initReadyWatcher() {
      if (this.initReadyWatcher) {
        this.initReady = true
        setTimeout(() => {
          this.bagReady = true
          this.bagButtonReady = true
        }, 400)
      }
    },
  },
  async created() {
    try {
      await this.getQuestion({
        questionnaireCode: this.questionnaireCode,
      })
      await this.refreshBag()
      const { title, subtitle } = this.question.data.assignment

      this.$prependTitle(
        `${title} | ${subtitle} | `
      )
      this.fetchMissionItems()
    } catch (error) {
      console.error(error)
    }
  },
  mounted() {
    this.contentReady = true
  },
  beforeDestroy() {
    this.clearQuestion()
    this.resetQuestionHelpers()
  },
  methods: {
    ...mapActions([
      'getQuestion',
      'getItemEffects',
      'getPageFlip',
      'getBagItems',
      'sendAnswer',
      'clearQuestion',
      'resetTip',
      'resetVideo',
      'resetPageFlip',
      'resetEffects',
    ]),
    fetchMissionItems() {
      const { params } = this.$route
      if (params.missionItem?.length) {
        params.missionItem.forEach((item) => {
          this.fetchItemEffects(item)
        })
      }
    },
    async setUseItem(payload) {
      this.useItemError = false
      this.waitingForItem = true
      playSoundFX()

      if (payload === 'missionBook') {
        this.fetchPageFlip()
      } else {
        try {
          await this.fetchItemEffects(payload)
          if (payload === 'goldenMagnifier') {
            this.canDisableOption = false
          }
          this.$refs.bag.hide()
          this.$refs[payload].show()
          this.handleCustomEvent(payload, true)
          playSoundFX(this.effectName(payload))
        } catch (error) {
          this.useItemError = true
          this.handleCustomEvent(payload, false)
          this.$refs[payload].hide()
          this.$toasted.global.error({
            title: this.$t(this.itemEffectErrorMessage),
          })
        } finally {
          this.waitingForItem = false
          this.refreshBag()
        }
      }
    },
    handleCloseModal({ type, reward }) {
      if (type.isChest) {
        this.$trackEvent({
          category: this.$track.category.mission,
          action: this.$track.action.receiveReward,
        })
      } else if (type.isLevel) {
        this.$trackEvent({
          category: this.$track.category.mission,
          action: this.$track.action.upLevel,
          label: parseInt(reward.value, 10),
        })
      }
    },
    handleCustomEvent(item, value) {
      switch (item) {
        case 'truthCompass':
          this.activeTruthCompassAnim = value
          break
        default:
      }
    },
    refreshBag() {
      const { assignment, id } = this.question.data

      this.getBagItems({
        missionId: assignment.code,
        questionId: id,
      })
    },
    resetQuestionHelpers() {
      this.resetEffects()
      this.resetTip()
      this.resetVideo()
      this.resetPageFlip()
      this.canDisableOption = false
    },
    optionClicked(clickedOption) {
      playSoundFX()
      if (this.selectedOption === clickedOption) {
        this.selectedOption = null
      } else {
        this.selectedOption = clickedOption
      }
    },
    async handleSendAnswer() {
      playSoundFX()
      const { assignmentCode } = this
      const answerBody = {
        questionId: this.question.data.id,
        markedOption: parseInt(JSON.parse(this.selectedOption.id), 10),
      }

      if (!this.userIsStudent) {
        answerBody.gradeId = this.gradeId
      }

      try {
        await this.sendAnswer({
          assignmentCode,
          answerBody,
        })
      } catch (error) {
        console.error(error)
      }
    },
    async nextEnigma() {
      playSoundFX()
      this.selectedOption = null
      this.resetQuestionHelpers()
      this.bagReady = false
      try {
        await this.getQuestion({ assignmentCode: this.assignmentCode })
      } catch (error) {
        console.error(error)
      }
    },
    showReport() {
      const { lectureId, questionnaireCode, chapterId } = this.$route.params
      playSoundFX()
      this.$router.push({
        name: 'report',
        query: { ...this.$route.query },
        params: {
          lectureId,
          questionnaireCode,
          chapterId,
          shouldPlaySoundEffect: true,
          shouldPlayAnimation: true,
        },
      })
    },
    quitMissionClicked() {
      playSoundFX()
      this.showQuitModal = true
    },
    quitMission() {
      playSoundFX()
      this.$trackEvent({
        category: this.$track.category.mission,
        action: this.$track.action.quitMission,
      })
      this.$router.push({
        name: 'assignments',
        query: { ...this.$route.query },
        params: { lectureId: this.$route.params.lectureId },
      })
    },
    cancelQuitMission() {
      playSoundFX()
      this.showQuitModal = false
    },
    async fetchItemEffects(id) {
      const { assignmentCode, question } = this

      return this.getItemEffects({
        id,
        assignmentCode,
        questionId: question.data.id,
        gradeId: this.gradeId,
      })
    },
    async fetchPageFlip() {
      try {
        if (!this.pageflip?.data?.contentPath) {
          await this.getPageFlip(this.questionnaireCode)
        }

        const { contentPath } = this.pageflip?.data
        const chapter = parseInt(this.$route.params.chapterId, 10)

        playSoundFX(this.effectName('wiseScroll'))

        this.$trackEvent({
          category: this.$track.category.eureka,
          action: this.$track.action.accessStudentPageflip,
          chapter,
          accountId: this.user.data.id,
        })

        this.$refs.bag.hide()
        window.open(contentPath, '_blank')
      } catch (error) {
        this.useItemError = true
      } finally {
        this.waitingForItem = false
      }
    },
    showExplanation() {
      playSoundFX()
      this.$refs.missionContent.showExplanation()
    },
    openBag() {
      playSoundFX(effectsNames.ZIPER_OPENING)

      this.$refs.bag.show()
      this.$trackEvent({
        category: this.$track.category.mission,
        action: this.$track.action.enterBag,
      })
    },
    handleAnimComplete() {
      this.$refs.truthCompass.hide()
      this.handleCustomEvent('truthCompass', false)
      playSoundFX(this.effectName('deleteAlternative'))
      this.canDisableOption = true
    },
    effectName(effect) {
      if (typeof effect === 'string') {
        const upperEffectName = effect.replace(/([A-Z])/g, '_$1').toUpperCase()

        return effectsNames[upperEffectName]
      }

      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
  .mission {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-position: center top;
    background-attachment: fixed;
    overflow: hidden;

    &__body {
      position: relative;
      z-index: 1;
      flex-grow: 1;
    }

    &__compass-animation {
      width: 0;
      position: fixed;
      background: #000;
      bottom: 0;
      right: 0;
      z-index: 100;
    }

    .mission-header {
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 4;
      margin-bottom: $size-m;
      transition: z-index 0.1s 2s;
    }

    .--compass-animation {
      .mission-header {
        z-index: 0;
        transition-delay: 0s;
      }
    }
  }

  .quick-actions {
    position: absolute;
    top: 120px;
    left: calc(50% + #{$mission-max-width / 2});
    z-index: 1;
    max-width: 0;
    overflow: visible;

    & > * {
      @include space-stack($size-s);
    }
  }

  .mission-content {
    z-index: 2;
  }

  .mission-actions,
  .mission-feedback {
    z-index: 3;
  }

  .--unclickable {
    pointer-events: none;
  }

  @include v-transition-in(truth-compass, ("dur-out": $speed-slow));

  @include v-transition(feedback, ("dur-out": $speed-slow)) {
    transform: translate(-50%, 100%);
  }

  @include v-transition(header) {
    transform: translateY(-100%);
  }

  @include v-transition(quick-actions) {
    transform: translateX(-100%);
  }

  @include v-transition(question-content, ("dur-in": 400ms, "dur-out": 400ms)) {
    transform: translateY(15%);
    opacity: (0);
  }

  .header__content {
    margin: $size-s 0 $size-m;
    padding: 0 $size-m;

    @media screen and (max-width: 479px) {
      margin: 0 0 12px;
    }

    .title {
      font-size: $font_size_heading_5;

      @include mq-l--mf {
        font-size: $font_size_heading_4;
      }

    }

    .subtitle {
      font-size: $font_size_s;
    }
  }
</style>
